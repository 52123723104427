import React, { useState } from "react";
import { motion } from "framer-motion";
import { FiX, FiCheck, FiAlertCircle } from "react-icons/fi";

const TicketModal = ({ closeModal, companyId, token }) => {
  const [ticketDetails, setTicketDetails] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!companyId || !ticketDetails.trim()) {
      setResult({ type: "error", message: "Preencha todos os campos" });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`https://api.vazo.info/api/ticket/${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ msg: ticketDetails }),
      });

      const data = await response.json();
      
      if (!response.ok) throw new Error(data.error || "Erro ao enviar ticket");

      setResult({ type: "success", message: "Ticket enviado com sucesso!" });
      setTimeout(closeModal, 2000);
    } catch (error) {
      setResult({ type: "error", message: error.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
    >
      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        className="bg-white rounded-xl w-full max-w-md"
      >
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h3 className="text-lg font-semibold">Novo Ticket</h3>
          <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
            <FiX className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          {result && (
            <div className={`p-3 rounded-lg flex items-center gap-2 ${
              result.type === "success" 
                ? "bg-green-50 text-green-700" 
                : "bg-red-50 text-red-700"
            }`}>
              {result.type === "success" ? (
                <FiCheck className="w-5 h-5" />
              ) : (
                <FiAlertCircle className="w-5 h-5" />
              )}
              <span>{result.message}</span>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Descrição do Problema
            </label>
            <textarea
              value={ticketDetails}
              onChange={(e) => setTicketDetails(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              rows="4"
              placeholder="Descreva detalhadamente o problema..."
              disabled={isSubmitting}
            />
          </div>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-4 py-2 text-gray-700 hover:bg-gray-50 rounded-lg"
              disabled={isSubmitting}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Enviando..." : "Enviar Ticket"}
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default TicketModal;