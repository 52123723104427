import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiDollarSign, FiMonitor, FiSettings, FiPlus, FiLogOut, FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { motion } from 'framer-motion';

const Sidebar = ({ setIsModalOpen }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    { 
      name: "Monitoramento", 
      icon: <FiMonitor className="w-5 h-5"/>, 
      path: "/dashboard" 
    },
    { 
      name: "Abrir Ticket", 
      icon: <FiPlus className="w-5 h-5"/>, 
      action: () => setIsModalOpen(true)
    },
    { 
      name: "Configurações", 
      icon: <FiSettings className="w-5 h-5"/>, 
      path: "/dashboard/configuracoes" 
    },
  ];

  return (
    <motion.aside
      initial={{ width: 80 }}
      animate={{ width: isCollapsed ? 80 : 256 }}
      className="h-screen bg-white border-r border-gray-200 hidden md:flex flex-col overflow-hidden"
    >
      <div className="p-4 flex items-center justify-between">
        {isCollapsed ? (
          <img 
            src="https://i.ibb.co/hFWqFHF4/vazo-traco.png" 
            alt="Logo" 
            className="h-8 w-8 mx-auto"
          />
        ) : (
          <img 
            src="https://vazo.info/vazo-png-logo.png" 
            alt="Logo" 
            className="h-8"
          />
        )}
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="text-gray-500 hover:text-emerald-600 p-1.5 rounded-lg hover:bg-gray-100 ml-2"
        >
          {isCollapsed ? (
            <FiChevronRight className="w-4 h-4 transform transition-transform" />
          ) : (
            <FiChevronLeft className="w-4 h-4 transform transition-transform" />
          )}
        </button>
      </div>

      <nav className="flex-1 space-y-1 px-2">
        {navItems.map((item) => (
          <button
            key={item.name}
            onClick={() => item.path ? navigate(item.path) : item.action()}
            className={`w-full flex items-center gap-3 p-3 rounded-lg transition-colors ${
              location.pathname === item.path 
                ? "bg-emerald-50 text-emerald-700" 
                : "text-gray-600 hover:bg-gray-50"
            } ${isCollapsed ? 'justify-center' : ''}`}
          >
            <span className={`${isCollapsed ? 'mx-auto' : ''}`}>
              {item.icon}
            </span>
            {!isCollapsed && <span className="text-sm font-medium">{item.name}</span>}
          </button>
        ))}
      </nav>

      <button
        onClick={() => {
          localStorage.clear();
          navigate("/login");
        }}
        className={`mt-auto flex items-center gap-3 p-3 text-gray-600 hover:bg-gray-50 mx-2 mb-4 rounded-lg ${
          isCollapsed ? 'justify-center' : ''
        }`}
      >
        <FiLogOut className="w-5 h-5"/>
        {!isCollapsed && <span className="text-sm font-medium">Sair</span>}
      </button>
    </motion.aside>
  );
};

export default Sidebar;