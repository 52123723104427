import React, { useState } from "react";
import { FiEye } from "react-icons/fi";
import { motion } from "framer-motion";

const LeakList = ({ 
  leaks, 
  setSelectedLeak, 
  setIsDetailModalOpen,
  currentPage,
  totalPages,
  setCurrentPage,
  formatDate
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedLeaks, setSelectedLeaks] = useState([]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedLeaks(selectAll ? [] : leaks.map(leak => leak.systemId));
  };

  const handleSelectLeak = (systemId) => {
    setSelectedLeaks(prev => 
      prev.includes(systemId) 
        ? prev.filter(id => id !== systemId) 
        : [...prev, systemId]
    );
  };

  const truncateId = (id) => id?.slice(0, 8) || '';

  return (
    <div className="overflow-hidden">
      {/* Desktop Table */}
      <table className="w-full hidden md:table">
        <thead className="bg-gray-50 text-gray-500 text-xs font-medium uppercase">
          <tr>
            <th className="px-6 py-4 text-left">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
                className="rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
              />
            </th>
            <th className="px-6 py-4 text-left">ID</th>
            <th className="px-6 py-4 text-left">Data</th>
            <th className="px-6 py-4 text-left">Status</th>
            <th className="px-6 py-4 text-right">Ações</th>
          </tr>
        </thead>
        
        <tbody className="divide-y divide-gray-200">
          {leaks.map((leak, index) => (
            <motion.tr
              key={leak.systemId}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.05 }}
              className="hover:bg-gray-50 cursor-pointer"
              onClick={() => {
                setSelectedLeak(leak);
                setIsDetailModalOpen(true);
              }}
            >
              <td className="px-6 py-4" onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={selectedLeaks.includes(leak.systemId)}
                  onChange={() => handleSelectLeak(leak.systemId)}
                  className="rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                />
              </td>
              <td className="px-6 py-4 font-medium text-sm">
                {truncateId(leak.systemId)}
              </td>
              <td className="px-6 py-4 text-sm text-gray-600">
                {formatDate(leak.date)}
              </td>
              <td className="px-6 py-4">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium 
                  ${leak.status === 'Aberto' 
                    ? 'bg-red-100 text-red-800' 
                    : 'bg-green-100 text-green-800'}`}
                >
                  {leak.status}
                </span>
              </td>
              <td className="px-6 py-4 text-right">
                <button
                  className="text-gray-400 hover:text-emerald-600 p-1.5 rounded-lg hover:bg-gray-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedLeak(leak);
                    setIsDetailModalOpen(true);
                  }}
                >
                  <FiEye className="w-5 h-5" />
                </button>
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>

      {/* Mobile List */}
      <div className="md:hidden space-y-2 p-2">
        {leaks.map((leak, index) => (
          <motion.div
            key={leak.systemId}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.05 }}
            className="bg-white p-4 rounded-lg shadow-sm border border-gray-200"
          >
            <div className="flex justify-between items-start">
              <div>
                <div className="flex items-center gap-2 mb-2">
                  <input
                    type="checkbox"
                    checked={selectedLeaks.includes(leak.systemId)}
                    onChange={() => handleSelectLeak(leak.systemId)}
                    className="rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                  />
                  <span className="font-medium text-sm">
                    {truncateId(leak.systemId)}
                  </span>
                </div>
                <span className="text-sm text-gray-600">
                  {formatDate(leak.date)}
                </span>
              </div>
              <button
                className="text-gray-400 hover:text-emerald-600"
                onClick={() => {
                  setSelectedLeak(leak);
                  setIsDetailModalOpen(true);
                }}
              >
                <FiEye className="w-5 h-5" />
              </button>
            </div>
            <div className="mt-2">
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium 
                ${leak.status === 'Aberto' 
                  ? 'bg-red-100 text-red-800' 
                  : 'bg-green-100 text-green-800'}`}
              >
                {leak.status}
              </span>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Pagination */}
      {leaks.length > 0 && (
        <div className="flex justify-between items-center px-6 py-4 border-t border-gray-200">
          <button
            className="px-4 py-2 text-gray-600 hover:text-emerald-600 disabled:opacity-50"
            onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <span className="text-sm text-gray-600">
            Página {currentPage} de {totalPages}
          </span>
          <button
            className="px-4 py-2 text-gray-600 hover:text-emerald-600 disabled:opacity-50"
            onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
            disabled={currentPage === totalPages}
          >
            Próxima
          </button>
        </div>
      )}

      {leaks.length === 0 && (
        <div className="p-8 text-center text-gray-500">
          Nenhum vazamento encontrado
        </div>
      )}
    </div>
  );
};

export default LeakList;