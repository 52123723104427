import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element: Element, adminOnly = false }) => {
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user') || "{}");
  const isAuthenticated = !!token;
  
  // Verifica se o usuário é admin, considerando que "permissions" é um array
  const isAdmin = user.permissions && user.permissions.includes("admin");

  // Se o usuário não estiver autenticado, redireciona para /login
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Se a rota for somente para admin e o usuário não for admin, redireciona para /dashboard
  if (adminOnly && !isAdmin) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Element />;
};

export default PrivateRoute;
