// pages/Admin/components/AdminNavbar.js
import React from 'react';

const AdminNavbar = () => {
  return (
    <div className="md:hidden bg-[#1a1a1a] text-white p-4 flex items-center justify-center">
      <img 
        src="vazo.info/vazo-admin-logo.png" 
        alt="Vazô Admin" 
        className="h-8"
      />
    </div>
  );
};

export default AdminNavbar;