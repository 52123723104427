import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import TicketModal from "./components/TicketModal";
import Navbar from "./components/Navbar";
import { motion } from "framer-motion";
import LeakDetailModal from "./components/LeakDetailModal";
import { FiCheckCircle, FiAlertCircle, FiEdit, FiX } from "react-icons/fi";
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const formatDate = (dateString) => {
  const parsedDate = parseISO(dateString);
  return format(parsedDate, 'dd/MM/yyyy HH:mm', { locale: ptBR });
};

const Configuracoes = () => {
  const token = localStorage.getItem("token");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const companyId = user?.companyId;

   const [activeTab] = useState("Monitoramento");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLeak, setSelectedLeak] = useState(null);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);


  // Estados do usuário
  const [userName, setUserName] = useState(user?.name || "");
  const [userEmail, setUserEmail] = useState(user?.email || "");
  const [oldPassword, setOldPassword] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPhone, setUserPhone] = useState(user?.phone || "");
  const [userMessage, setUserMessage] = useState("");

  // Estados da empresa
  const [companyName, setCompanyName] = useState("");
  const [companyDomains, setCompanyDomains] = useState([]);
  const [companyContactEmail, setCompanyContactEmail] = useState("");
  const [companyMessage, setCompanyMessage] = useState("");

  // Estados para edição de domínios
  const [editingDomainIndex, setEditingDomainIndex] = useState(-1);
  const [editedDomain, setEditedDomain] = useState("");

  useEffect(() => {
    if (companyId && token) {
      fetch(`https://api.vazo.info/api/companies/info/${companyId}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCompanyName(data.name || "");
          setCompanyDomains(Array.isArray(data.domains) ? data.domains : []);
          setCompanyContactEmail(data.contactEmail || "");
        })
        .catch((error) => console.error("Erro ao buscar dados da empresa:", error));
    }
  }, [companyId, token]);

  const handleUserProfileSubmit = async (e) => {
    e.preventDefault();
    if (userPassword && !oldPassword) {
      setUserMessage("Informe sua senha antiga para alterar a senha.");
      return;
    }
    const payload = {
      name: userName,
      email: userEmail,
      phone: userPhone,
    };
    if (userPassword) {
      payload.password = userPassword;
      payload.oldPassword = oldPassword;
    }
    try {
      const response = await fetch("https://api.vazo.info/api/users/profile/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        setUserMessage("Perfil atualizado com sucesso.");
        const updatedUser = { ...user, name: userName, email: userEmail, phone: userPhone};
        localStorage.setItem("user", JSON.stringify(updatedUser));
        setOldPassword("");
        setUserPassword("");
      } else {
        setUserMessage(data.error || "Erro ao atualizar perfil.");
      }
    } catch (error) {
      console.error("Erro ao atualizar perfil:", error);
      setUserMessage("Erro ao atualizar perfil.");
    }
  };

  const handleCompanyInfoSubmit = async (e) => {
    e.preventDefault();
    if (!companyId) {
      setCompanyMessage("Nenhuma empresa associada.");
      return;
    }
    const payload = {
      name: companyName,
      domains: companyDomains,
      contactEmail: companyContactEmail,
      companyId: companyId,
    };
    try {
      const response = await fetch(`https://api.vazo.info/api/companies/update-info/${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        setCompanyMessage("Informações da empresa atualizadas com sucesso.");
      } else {
        setCompanyMessage(data.error || "Erro ao atualizar informações da empresa.");
      }
    } catch (error) {
      console.error("Erro ao atualizar informações da empresa:", error);
      setCompanyMessage("Erro ao atualizar informações da empresa.");
    }
  };

  const handleEditDomain = (index, newDomain) => {
    const updatedDomains = [...companyDomains];
    const cleanDomain = newDomain.trim().toLowerCase();
    
    if (cleanDomain && !updatedDomains.includes(cleanDomain)) {
      updatedDomains[index] = cleanDomain;
      setCompanyDomains(updatedDomains);
    }
  };

  const handleRemoveDomain = (index) => {
    setCompanyDomains(companyDomains.filter((_, i) => i !== index));
  };


  return (
    <div className="flex min-h-screen bg-gray-50">
    <Sidebar setIsModalOpen={setIsModalOpen} />
      <div className="flex-1 flex flex-col">
        <Navbar />
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex-1 p-4 md:p-6 lg:p-8"
        >
          <div className="max-w-7xl mx-auto">
            <h1 className="text-2xl font-bold text-gray-900 mb-6">Configurações</h1>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8">
              {/* Coluna Esquerda - Perfil do Usuário */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                <h2 className="text-lg font-semibold text-gray-900 mb-6 pb-4 border-b border-gray-200">
                  Perfil Pessoal
                </h2>
                
                <form onSubmit={handleUserProfileSubmit} className="space-y-4">
                <div className="grid grid-cols-1 gap-4">
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">Nome Completo</label>
    <input
      type="text"
      value={userName}
      onChange={(e) => setUserName(e.target.value)}
      className="w-full px-4 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-emerald-500"
    />
  </div>

  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
    <input
      type="email"
      value={userEmail}
      onChange={(e) => setUserEmail(e.target.value)}
      className="w-full px-4 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-emerald-500"
    />
  </div>
</div>

                  <div className="pt-4 border-t border-gray-200">
                    <h3 className="text-sm font-medium text-gray-700 mb-3">Alterar Senha</h3>
                    <div className="space-y-4">
                      <input
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Senha atual"
                        className="w-full px-4 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-emerald-500"
                      />
                      <input
                        type="password"
                        value={userPassword}
                        onChange={(e) => setUserPassword(e.target.value)}
                        placeholder="Nova senha"
                        className="w-full px-4 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-emerald-500"
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="w-full mt-6 bg-emerald-600 hover:bg-emerald-700 text-white font-medium py-2.5 rounded-lg transition-colors"
                  >
                    Salvar Alterações
                  </button>
                  
                  {userMessage && (
                    <div className={`mt-4 p-3 rounded-lg flex items-center gap-2 ${
                      userMessage.includes("sucesso") 
                        ? "bg-green-50 text-green-700" 
                        : "bg-red-50 text-red-700"
                    }`}>
                      {userMessage.includes("sucesso") ? (
                        <FiCheckCircle className="w-5 h-5" />
                      ) : (
                        <FiAlertCircle className="w-5 h-5" />
                      )}
                      <span>{userMessage}</span>
                    </div>
                  )}
                </form>
              </div>

              {/* Coluna Direita - Configurações da Empresa */}
              {companyId && (
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                  <h2 className="text-lg font-semibold text-gray-900 mb-6 pb-4 border-b border-gray-200">
                    Configurações da Empresa
                  </h2>
                  
                  <form onSubmit={handleCompanyInfoSubmit} className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">Email para Notificações</label>
                      <input
                        type="email"
                        value={companyContactEmail}
                        onChange={(e) => setCompanyContactEmail(e.target.value)}
                        className="w-full px-4 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-emerald-500"
                        placeholder="notificacoes@empresa.com"
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">WhatsApp Notificações</label>
                      <input
                        type="tel"
                        value={userPhone}
                        onChange={(e) => setUserPhone(e.target.value)}
                        className="w-full px-4 py-2.5 rounded-lg border border-gray-200 focus:ring-2 focus:ring-emerald-500"
                        placeholder="(00) 00000-0000"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">Domínios Monitorados</label>
                      
                      <div className="flex flex-wrap gap-2">
                        {companyDomains.map((domain, index) => (
                          <div
                            key={index}
                            className="relative flex items-center bg-gray-50 rounded-md px-3 py-2 border border-gray-200 group"
                          >
                            {editingDomainIndex === index ? (
                              <input
                                type="text"
                                value={editedDomain}
                                onChange={(e) => setEditedDomain(e.target.value)}
                                onBlur={() => {
                                  handleEditDomain(index, editedDomain);
                                  setEditingDomainIndex(-1);
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    handleEditDomain(index, editedDomain);
                                    setEditingDomainIndex(-1);
                                  }
                                }}
                                autoFocus
                                className="bg-transparent outline-none text-sm w-32 font-medium text-gray-700"
                              />
                            ) : (
                              <>
                                <span 
                                  className="text-sm text-gray-700 cursor-text pr-5"
                                  onClick={() => {
                                    setEditedDomain(domain);
                                    setEditingDomainIndex(index);
                                  }}
                                >
                                  {domain}
                                </span>
                                <button
                                  type="button"
                                  onClick={() => handleRemoveDomain(index)}
                                  className="absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-sm text-gray-400 hover:text-red-600 transition-colors"
                                >
                                  <FiX className="w-4 h-4" />
                                </button>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                      
                      <p className="mt-2 text-xs text-gray-500">
                        Clique para editar ou no ícone × para remover
                      </p>
                    </div>

                    <button
                      type="submit"
                      className="w-full mt-6 bg-emerald-600 hover:bg-emerald-700 text-white font-medium py-2.5 rounded-lg transition-colors"
                    >
                      Atualizar Configurações
                    </button>
                    
                    {companyMessage && (
                      <div className={`mt-4 p-3 rounded-lg flex items-center gap-2 ${
                        companyMessage.includes("sucesso") 
                          ? "bg-green-50 text-green-700" 
                          : "bg-red-50 text-red-700"
                      }`}>
                        {companyMessage.includes("sucesso") ? (
                          <FiCheckCircle className="w-5 h-5" />
                        ) : (
                          <FiAlertCircle className="w-5 h-5" />
                        )}
                        <span>{companyMessage}</span>
                      </div>
                    )}
                  </form>
                </div>
              )}
            </div>
          </div>
        </motion.div>
        {isModalOpen && companyId && (
          <TicketModal 
            closeModal={() => setIsModalOpen(false)}
            companyId={companyId}
            token={token}
          />
        )}

        {isDetailModalOpen && selectedLeak && (
          <LeakDetailModal 
            leak={selectedLeak}
            closeModal={() => setIsDetailModalOpen(false)}
            companyId={companyId}
            token={token}
            formatDate={formatDate}
          />
        )}

      </div>
    </div>
    
  );
};



export default Configuracoes;
