import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { motion, AnimatePresence } from "framer-motion";
import { FiDownload, FiDollarSign, FiCreditCard, FiCheckCircle, FiX, FiLink, FiClock, FiFileText, FiCopy } from "react-icons/fi";
import { QRCodeCanvas } from "qrcode.react";
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const formatDate = (dateString) => {
  const parsedDate = parseISO(dateString);
  return format(parsedDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR });
};

const Financeiro = () => {
  // Estados
  const [showPixModal, setShowPixModal] = useState(false);
  const [copyFeedback, setCopyFeedback] = useState(false);

  // Dados
  const activePlan = {
    name: "Plano Empresarial Pro",
    price: 997.90,
    status: "active",
    benefits: [
      "Monitoramento de 15 domínios",
      "Equipe dedicada",
      "Treinamentos personalizados",
      "Notificações Automáticas - E-Mail/Whatsapp"
    ]
  };

  const currentInvoice = {
    dueDate: "2023-09-10",
    amount: 997.90,
    status: "pending"
  };

  const paymentHistory = [
    {
      id: "PAY-001",
      amount: 997.90,
      paymentDate: "2023-08-10",
      status: "paid",
      method: "PIX"
    }
  ];

  const pixData = "00020126580014BR.GOV.BCB.PIX0136123e4567-e12b-12d1-a456-4266554400005204000053039865405997.905802BR5913Empresa LTDA6008BRASILIA62070503***6304";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pixData);
    setCopyFeedback(true);
    setTimeout(() => setCopyFeedback(false), 2000);
  };

  const StatusBadge = ({ status }) => (
    <span className={`px-3 py-1 rounded-full text-sm font-medium ${
      status === 'active' ? 'bg-emerald-100 text-emerald-800' : 
      status === 'pending' ? 'bg-amber-100 text-amber-800' : 'bg-gray-100 text-gray-800'
    }`}>
      {status === 'active' ? 'Ativo' : 'Pendente'}
    </span>
  );

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Navbar />
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex-1 p-4 md:p-6 lg:p-8 grid grid-cols-1 lg:grid-cols-3 gap-6 h-[calc(100vh-80px)]"
        >
          {/* Coluna 1 - Plano Ativo */}
          <div className="bg-white rounded-2xl shadow-lg border border-gray-100 p-6 flex flex-col">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                <div className="p-2 bg-emerald-100 rounded-lg">
                  <FiFileText className="w-5 h-5 text-emerald-600" />
                </div>
                Plano Contratado
              </h2>
              <StatusBadge status={activePlan.status} />
            </div>
            
            <div className="flex-1 space-y-6">
              <div className="space-y-4">
                <div className="bg-gradient-to-br from-emerald-50 to-emerald-100 p-4 rounded-xl">
                  <div className="flex items-baseline gap-2">
                    <span className="text-2xl font-bold text-emerald-600">
                      R$ {activePlan.price.toFixed(2).replace('.', ',')}
                    </span>
                    <span className="text-gray-500">/mês</span>
                  </div>
                </div>

                <div className="space-y-4">
                  <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wide">Benefícios incluídos</h3>
                  <ul className="space-y-3">
                    {activePlan.benefits.map((benefit, index) => (
                      <motion.li 
                        key={index} 
                        className="flex items-center gap-3 text-gray-600 p-3 hover:bg-gray-50 rounded-lg transition-colors"
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1 }}
                      >
                        <div className="w-7 h-7 bg-emerald-100 rounded-lg flex items-center justify-center">
                          <FiCheckCircle className="w-4 h-4 text-emerald-600" />
                        </div>
                        {benefit}
                      </motion.li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Coluna 2 - Fatura em Aberto */}
          <div className="bg-white rounded-2xl shadow-lg border border-gray-100 p-6 flex flex-col">
            <div className="flex-1 space-y-6">
              <div className="flex items-start justify-between mb-4">
                <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                  <div className="p-2 bg-amber-100 rounded-lg">
                    <FiClock className="w-5 h-5 text-amber-600" />
                  </div>
                  Fatura em Aberto
                </h2>
                <StatusBadge status={currentInvoice.status} />
              </div>

              <div className="space-y-6">
                <div className="bg-gradient-to-br from-amber-50 to-amber-100 p-4 rounded-xl">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-sm text-amber-600">Vencimento</p>
                      <p className="font-bold text-amber-700">
                        {formatDate(currentInvoice.dueDate)}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-sm text-amber-600">Valor Total</p>
                      <p className="font-bold text-amber-700">
                        R$ {currentInvoice.amount.toFixed(2).replace('.', ',')}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-3">
                  <motion.button
                    whileHover={{ y: -2 }}
                    onClick={() => setShowPixModal(true)}
                    className="h-24 flex flex-col items-center justify-center gap-2 bg-gradient-to-br from-emerald-600 to-emerald-700 hover:from-emerald-700 hover:to-emerald-800 text-white p-4 rounded-xl transition-all shadow-lg hover:shadow-emerald-100"
                  >
                    <FiDollarSign className="w-6 h-6" />
                    <span className="text-sm font-medium">Pagar com PIX</span>
                  </motion.button>

                  <motion.button
                    whileHover={{ y: -2 }}
                    className="h-24 flex flex-col items-center justify-center gap-2 bg-gradient-to-br from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white p-4 rounded-xl transition-all shadow-lg hover:shadow-blue-100"
                  >
                    <FiCreditCard className="w-6 h-6" />
                    <span className="text-sm font-medium">Boleto Bancário</span>
                  </motion.button>

                  <motion.button
                    whileHover={{ y: -2 }}
                    className="col-span-2 h-24 flex flex-col items-center justify-center gap-2 bg-gradient-to-br from-purple-600 to-purple-700 hover:from-purple-700 hover:to-purple-800 text-white p-4 rounded-xl transition-all shadow-lg hover:shadow-purple-100"
                  >
                    <FiLink className="w-6 h-6" />
                    <span className="text-sm font-medium">Link de Pagamento</span>
                  </motion.button>
                </div>
              </div>
            </div>
          </div>

          {/* Coluna 3 - Histórico */}
          <div className="bg-white rounded-2xl shadow-lg border border-gray-100 p-6 flex flex-col">
            <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
              <div className="p-2 bg-emerald-100 rounded-lg">
                <FiFileText className="w-5 h-5 text-emerald-600" />
              </div>
              Histórico Recente
            </h2>
            
            <div className="flex-1 overflow-y-auto space-y-2">
              {paymentHistory.map((payment, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="group p-3 bg-gray-50 hover:bg-white rounded-lg flex justify-between items-center border border-transparent hover:border-emerald-100 transition-all cursor-pointer"
                >
                  <div>
                    <p className="font-medium text-emerald-600">
                      R$ {payment.amount.toFixed(2).replace('.', ',')}
                    </p>
                    <p className="text-sm text-gray-500">
                      {formatDate(payment.paymentDate)} • {payment.method}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-xs font-medium text-emerald-600 bg-emerald-100 px-2 py-1 rounded-full">
                      Pago
                    </span>
                    <FiDownload className="text-gray-400 group-hover:text-emerald-600 transition-colors" />
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Modal PIX */}
          <AnimatePresence>
            {showPixModal && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 backdrop-blur-sm"
              >
                <motion.div
                  initial={{ scale: 0.95, y: 20 }}
                  animate={{ scale: 1, y: 0 }}
                  className="bg-white rounded-2xl p-6 max-w-md w-full relative shadow-2xl"
                >
                  <div className="flex justify-between items-center mb-6">
                    <h3 className="text-xl font-semibold flex items-center gap-2">
                      <div className="p-2 bg-emerald-100 rounded-lg">
                        <FiDollarSign className="w-6 h-6 text-emerald-600" />
                      </div>
                      Pagamento via PIX
                    </h3>
                    <button
                      onClick={() => setShowPixModal(false)}
                      className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
                    >
                      <FiX className="w-6 h-6" />
                    </button>
                  </div>

                  <div className="flex flex-col items-center space-y-6">
                    <div className="p-4 bg-white border-2 border-emerald-100 rounded-xl relative">
                      <QRCodeCanvas 
                        value={pixData}
                        size={200}
                        bgColor="#FFFFFF"
                        fgColor="#059669"
                        level="H"
                      />
                      <div className="absolute inset-0 flex items-center justify-center animate-pulse">
                        <div className="w-24 h-24 border-4 border-emerald-100 rounded-lg" />
                      </div>
                    </div>
                    
                    <div className="w-full space-y-4">
                      <div className="bg-gray-50 p-3 rounded-lg flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-600 truncate mr-2">
                          {pixData}
                        </span>
                        <button
                          onClick={copyToClipboard}
                          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                        >
                          <FiCopy className="w-5 h-5 text-gray-600" />
                        </button>
                      </div>

                      <AnimatePresence>
                        {copyFeedback && (
                          <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className="text-center p-2 bg-emerald-100 text-emerald-600 rounded-lg text-sm"
                          >
                            Chave PIX copiada!
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default Financeiro;