// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilo básico para o App */
.App {
    padding: 0;
  }
  
/* Para scroll sutil em todos os navegadores */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.05);
}

::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.1);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.15);
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,UAAU;EACZ;;AAEF,8CAA8C;AAC9C;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":["/* Estilo básico para o App */\r\n.App {\r\n    padding: 0;\r\n  }\r\n  \r\n/* Para scroll sutil em todos os navegadores */\r\n::-webkit-scrollbar {\r\n  width: 8px;\r\n  height: 8px;\r\n}\r\n\r\n::-webkit-scrollbar-track {\r\n  background: rgba(0,0,0,0.05);\r\n}\r\n\r\n::-webkit-scrollbar-thumb {\r\n  background: rgba(0,0,0,0.1);\r\n  border-radius: 4px;\r\n}\r\n\r\n::-webkit-scrollbar-thumb:hover {\r\n  background: rgba(0,0,0,0.15);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
