import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FiDownload, FiX, FiArrowLeft } from "react-icons/fi";

const LeakDetailModal = ({ leak, closeModal, companyId, token, formatDate }) => {
  const [leakDetails, setLeakDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [slowResponse, setSlowResponse] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchLeakDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        setSlowResponse(false);
        
        const timeoutId = setTimeout(() => setSlowResponse(true), 10000);

        const response = await fetch(
          `https://api.vazo.info/api/view/${companyId}/${leak.systemId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            signal: abortController.signal,
          }
        );

        clearTimeout(timeoutId);

        if (!response.ok) throw new Error(`Erro HTTP! status: ${response.status}`);

        const data = await response.json();
        
        setLeakDetails({
          content: data.content.map(entry => entry.content.replace(/\n/g, '<br>')),
          domain: data.domain,
          date: data.metadata?.date || leak.date,
          viewCount: data.metadata?.viewCount || 0,
          systemId: data?.systemId || 'N/A'
        });
        
      } catch (error) {
        if (error.name !== "AbortError") {
          setError(error.message || "Erro ao carregar detalhes");
          setTimeout(fetchLeakDetails, 5000);
        }
      } finally {
        if (!abortController.signal.aborted) {
          setLoading(false);
          setSlowResponse(false);
        }
      }
    };

    if (leak?.systemId && companyId && token) fetchLeakDetails();

    return () => abortController.abort();
  }, [leak, companyId, token]);

  const handleDownload = async () => {
    try {
      const response = await fetch(
        `https://api.vazo.info/api/download/${companyId}/${leak.systemId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (!response.ok) throw new Error(`Erro HTTP! status: ${response.status}`);

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `leak-${leak.systemId}.txt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      setError(error.message || "Erro ao baixar o arquivo");
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') closeModal();
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
    >
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        className="bg-white rounded-xl w-full max-w-2xl max-h-[90vh] flex flex-col"
      >
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h3 className="text-lg font-semibold">Detalhes do Vazamento</h3>
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700"
          >
            <FiX className="w-5 h-5" />
          </button>
        </div>


        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {loading && (
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-600"></div>
            </div>
          )}

          {slowResponse && (
            <div className="bg-yellow-50 text-yellow-700 p-3 rounded-lg text-center">
              Consultando banco de dados...
            </div>
          )}

          {error && (
            <div className="bg-red-50 text-red-700 p-3 rounded-lg">
              {error}
            </div>
          )}

          {leakDetails && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Data</p>
                  <p className="font-medium">{formatDate(leakDetails.date)}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Visualizações</p>
                  <p className="font-medium">{leakDetails.viewCount}</p>
                </div>
                <div className="col-span-2">
                  <p className="text-sm text-gray-500">Domínio</p>
                  <p className="font-medium">{leakDetails.domain}</p>
                </div>
              </div>

              <div>
                <p className="text-sm text-gray-500 mb-2">Conteúdo</p>
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <div className="font-mono text-sm text-gray-700 overflow-auto max-h-96">
                    {leakDetails.content.map((entry, index) => (
                      <pre 
                        key={index}
                        className="whitespace-pre-wrap break-words"
                        dangerouslySetInnerHTML={{ __html: entry }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="p-4 border-t border-gray-200 flex justify-between gap-2">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-gray-700 hover:bg-gray-50 rounded-lg flex items-center gap-2"
          >
            <FiArrowLeft className="w-4 h-4" />
            Voltar
          </button>
          <button
            onClick={handleDownload}
            className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 flex items-center gap-2"
          >
            <FiDownload className="w-4 h-4" />
            Baixar
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default LeakDetailModal;