import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiBriefcase, FiSearch, FiTrash2, FiEdit2, FiPlus, FiX, FiCheckCircle, FiAlertCircle, FiChevronLeft, FiChevronRight 

} from 'react-icons/fi';

const ITEMS_PER_PAGE = 10;

const notificationVariants = {
  initial: { opacity: 0, y: 50, x: 50 },
  animate: { opacity: 1, y: 0, x: 0 },
  exit: { opacity: 0, x: 50, transition: { duration: 0.2 } }
};

const modalVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 }
};

const AdminEmpresas = ({ setGlobalLoading }) => {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
      setSuccessMessage('');
    }, 5000);
    return () => clearTimeout(timer);
  }, [error, successMessage]);

  useEffect(() => {
    fetchCompanies();
  }, [token, setGlobalLoading]);

  const fetchCompanies = async () => {
    setGlobalLoading(true);
    try {
      const response = await fetch('https://api.vazo.info/api/companies/list', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      if (!response.ok) throw new Error('Erro ao carregar empresas');
      const data = await response.json();
      setCompanies(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const method = modalData?.id ? 'PUT' : 'POST';
      const url = modalData?.id 
        ? `https://api.vazo.info/api/companies/update/${modalData.id}`
        : 'https://api.vazo.info/api/companies/create';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          ...modalData,
          domains: modalData.domains.filter(d => d.trim() !== '')
        })
      });

      if (!response.ok) throw new Error('Erro ao salvar empresa');
      
      setSuccessMessage(modalData?.id ? 'Empresa atualizada!' : 'Empresa criada!');
      fetchCompanies();
      setShowModal(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Tem certeza que deseja excluir esta empresa?')) return;
    
    try {
      const response = await fetch(`https://api.vazo.info/api/companies/delete/${id}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!response.ok) throw new Error('Erro ao excluir empresa');
      
      setSuccessMessage('Empresa excluída com sucesso!');
      fetchCompanies();
    } catch (error) {
      setError(error.message);
    }
  };

  const filteredCompanies = companies.filter(company =>
    company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.domains.some(d => d.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const paginatedCompanies = filteredCompanies.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const totalPages = Math.ceil(filteredCompanies.length / ITEMS_PER_PAGE);

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <AnimatePresence>
        {(error || successMessage) && (
          <motion.div
            variants={notificationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={`fixed top-4 right-4 p-4 rounded-lg flex items-center gap-2 z-50 ${
              error ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'
            }`}
          >
            {error ? (
              <FiAlertCircle className="w-5 h-5" />
            ) : (
              <FiCheckCircle className="w-5 h-5" />
            )}
            <span>{error || successMessage}</span>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="p-4 border-b border-gray-200 flex flex-col md:flex-row justify-between gap-4">
        <div className="flex items-center gap-4">
          <h3 className="text-lg font-semibold">Empresas Cadastradas</h3>

        </div>
        
        <div className="relative max-w-xs">
          <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Pesquisar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-emerald-500"
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50 text-gray-500 text-xs uppercase">
            <tr>
              <th className="px-6 py-3 text-left">Empresa</th>
              <th className="px-6 py-3 text-left">Domínios</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-right">Ações</th>
            </tr>
          </thead>
          
          <tbody className="divide-y divide-gray-200">
            {paginatedCompanies.map((company) => (
              <motion.tr
                key={company.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="hover:bg-gray-50"
              >
                <td className="px-6 py-4 font-medium">
                  <div className="flex items-center gap-3">
                    <div className="bg-emerald-100 p-2 rounded-lg">
                      <FiBriefcase className="w-5 h-5 text-emerald-600" />
                    </div>
                    {company.name}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex flex-wrap gap-1 max-w-[200px]">
                    {company.domains.map(domain => (
                      <span key={domain} className="px-2 py-1 bg-gray-100 text-gray-700 rounded-full text-xs">
                        {domain}
                      </span>
                    ))}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    company.status === 'active' 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {company.status}
                  </span>
                </td>
                <td className="px-6 py-4 text-right">
                  <div className="flex gap-2 justify-end">
                    <button 
                      onClick={() => {
                        setModalData(company);
                        setShowModal(true);
                      }}
                      className="text-gray-400 hover:text-emerald-600 p-1.5 rounded-lg"
                    >
                      <FiEdit2 className="w-5 h-5" />
                    </button>
                    <button 
                      onClick={() => handleDelete(company.id)}
                      className="text-gray-400 hover:text-red-600 p-1.5 rounded-lg"
                    >
                      <FiTrash2 className="w-5 h-5" />
                    </button>
                  </div>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>

      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
          >
            <motion.div
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="bg-white rounded-xl w-full max-w-md p-6 relative max-h-[90vh] overflow-y-auto"
            >
              <button
                onClick={() => setShowModal(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              >
                <FiX className="w-5 h-5" />
              </button>

              <h3 className="text-lg font-semibold mb-4">
                {modalData?.id ? 'Editar Empresa' : 'Nova Empresa'}
              </h3>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Nome*</label>
                  <input
                    type="text"
                    value={modalData?.name || ''}
                    onChange={(e) => setModalData({...modalData, name: e.target.value})}
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">Domínios</label>
                  <div className="space-y-2">
                    <AnimatePresence>
                      {modalData?.domains?.map((domain, index) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          className="flex gap-2 items-center"
                        >
                          <input
                            type="text"
                            value={domain}
                            onChange={(e) => {
                              const newDomains = [...modalData.domains];
                              newDomains[index] = e.target.value;
                              setModalData({...modalData, domains: newDomains});
                            }}
                            className="w-full px-3 py-2 border rounded-lg"
                            placeholder="Domínio"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              const newDomains = modalData.domains.filter((_, i) => i !== index);
                              setModalData({...modalData, domains: newDomains});
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            <FiX className="w-4 h-4" />
                          </button>
                        </motion.div>
                      ))}
                    </AnimatePresence>
                    <button
                      type="button"
                      onClick={() => {
                        setModalData({
                          ...modalData,
                          domains: [...(modalData.domains || []), '']
                        });
                      }}
                      className="text-emerald-600 hover:text-emerald-700 flex items-center gap-1 text-sm"
                    >
                      <FiPlus className="w-4 h-4" />
                      Adicionar Domínio
                    </button>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">Status*</label>
                  <select
                    value={modalData?.status || 'active'}
                    onChange={(e) => setModalData({...modalData, status: e.target.value})}
                    className="w-full px-3 py-2 border rounded-lg"
                  >
                    <option value="active">Ativa</option>
                    <option value="inactive">Inativa</option>
                  </select>
                </div>

                <div className="mt-6 flex gap-3 justify-end">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="px-4 py-2 border rounded-lg hover:bg-gray-50 transition-colors"
                    disabled={isSubmitting}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 flex items-center gap-2 transition-colors"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity }}
                        className="w-4 h-4 border-2 border-t-transparent border-white rounded-full"
                      />
                    )}
                    {modalData?.id ? 'Salvar' : 'Criar'}
                  </button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="p-4 border-t border-gray-200 flex justify-between items-center">
        <div className="text-sm text-gray-600">
          Mostrando {paginatedCompanies.length} de {filteredCompanies.length} empresas
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            className="p-2 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
          >
            <FiChevronLeft className="w-5 h-5" />
          </button>
          
          <span className="px-4 py-2 text-sm">
            Página {currentPage} de {totalPages}
          </span>

          <button
            onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
            disabled={currentPage === totalPages}
            className="p-2 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
          >
            <FiChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminEmpresas;