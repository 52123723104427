import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import LeakList from "./components/LeakList";
import TicketModal from "./components/TicketModal";
import LeakDetailModal from "./components/LeakDetailModal";
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { motion } from "framer-motion";

const formatDate = (dateString) => {
  const parsedDate = parseISO(dateString);
  return format(parsedDate, 'dd/MM/yyyy HH:mm', { locale: ptBR });
};

const Dashboard = () => {
  const [activeTab] = useState("Monitoramento");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeak, setSelectedLeak] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Geral");
  const [statusData, setStatusData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://api.vazo.info/api/status/${companyId}`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        
        if (!response.ok) throw new Error("Erro ao buscar dados");
        const data = await response.json();
        
        setStatusData(data);
        setError(null);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (companyId && token) fetchStatus();
  }, [companyId, token]);

  const allLeaks = [
    ...(statusData?.vazamentos_hoje?.leaks || []),
    ...(statusData?.vazamentos_total?.leaks || [])
  ];
  
  const filterLeaks = () => {
    if (!allLeaks.length) return [];
    const now = Date.now();
    
    return allLeaks.filter(leak => {
      const leakDate = new Date(leak.date).getTime();
      switch(selectedFilter) {
        case "Hoje": return leakDate > now - 86400000;
        case "3 dias": return leakDate > now - 259200000;
        case "7 dias": return leakDate > now - 604800000;
        case "30 dias": return leakDate > now - 2592000000;
        default: return true;
      }
    });
  };

  const filteredLeaks = filterLeaks();
  const paginatedLeaks = filteredLeaks.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(filteredLeaks.length / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilter]);

  return (
    <div className="flex min-h-screen bg-gray-50">
      <Sidebar setIsModalOpen={setIsModalOpen} />
      
      <div className="flex-1 flex flex-col transition-all duration-300">
        <Navbar />

        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex-1 overflow-y-auto p-4 md:p-6"
        >
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
              <div className="flex flex-col">
                <h1 className="text-xl md:text-2xl font-bold text-gray-900">
                  {activeTab === "Monitoramento" ? "Monitoramento" : "Estatísticas"}
                </h1>
                <span className="text-sm text-gray-500 mt-1">
                  {filteredLeaks.length} Vazamentos protegidos
                </span>
              </div>
              
              <div className="flex flex-wrap gap-2 w-full md:w-auto">
                {["Hoje", "3 dias", "7 dias", "30 dias", "Geral"].map(filter => (
                  <button
                    key={filter}
                    onClick={() => setSelectedFilter(filter)}
                    className={`px-3 py-1 text-sm rounded-full transition-colors ${
                      selectedFilter === filter
                        ? "bg-emerald-600 text-white"
                        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    }`}
                  >
                    {filter}
                  </button>
                ))}
              </div>
            </div>

            {loading ? (
              <div className="flex justify-center py-12">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-600"></div>
              </div>
            ) : error ? (
              <div className="bg-red-50 p-4 rounded-lg text-red-700">
                Erro: {error}
              </div>
            ) : (
              <motion.div
                initial={{ y: 20 }}
                animate={{ y: 0 }}
                className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
              >
                <LeakList 
                  leaks={paginatedLeaks}
                  formatDate={formatDate}
                  setSelectedLeak={setSelectedLeak}
                  setIsDetailModalOpen={setIsDetailModalOpen}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </motion.div>
            )}
          </div>
        </motion.main>

        {isModalOpen && companyId && (
          <TicketModal 
            closeModal={() => setIsModalOpen(false)}
            companyId={companyId}
            token={token}
          />
        )}

        {isDetailModalOpen && selectedLeak && (
          <LeakDetailModal 
            leak={selectedLeak}
            closeModal={() => setIsDetailModalOpen(false)}
            companyId={companyId}
            token={token}
            formatDate={formatDate}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;