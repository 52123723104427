import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiMenu, FiX, FiMonitor, FiSettings, FiPlus, FiLogOut } from 'react-icons/fi';
import { motion } from 'framer-motion';
import TicketModal from "./TicketModal";

const Navbar = () => {
  const token = localStorage.getItem("token");
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const companyId = user?.companyId;
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) setIsOpen(false);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navItems = [
    { 
      name: "Monitoramento", 
      icon: <FiMonitor className="w-5 h-5 text-gray-600"/>, 
      path: "/dashboard" 
    },
    { 
      name: "Abrir Ticket", 
      icon: <FiPlus className="w-5 h-5 text-gray-600"/>, 
      action: () => {
        setIsModalOpen(true);
        setIsOpen(false);
      }
    },
    { 
      name: "Configurações", 
      icon: <FiSettings className="w-5 h-5 text-gray-600"/>, 
      path: "/dashboard/configuracoes" 
    },
  ];

  return (
    <>
    <header>
      <div className="md:hidden fixed top-4 right-4 z-50">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 text-gray-600 bg-white/90 rounded-lg hover:bg-gray-50 shadow-sm border border-gray-200"
        >
          {isOpen ? <FiX className="w-6 h-6" /> : <FiMenu className="w-6 h-6" />}
        </button>
      </div>
      </header>

      <motion.nav
        initial={{ x: '-100%' }}
        animate={{ x: isOpen ? 0 : '-100%' }}
        transition={{ type: 'tween' }}
        className="fixed md:hidden top-0 left-0 h-full w-64 bg-white z-40 shadow-xl border-r border-gray-200"
      >
        <div className="p-4 border-b border-gray-200">
          <img
            src="https://vazo.info/vazo-png-logo.png"
            alt="Logo"
            className="w-32 h-auto mx-auto"
          />
        </div>

        <div className="flex flex-col pt-4 space-y-1 px-2">
          {navItems.map((item) => (
            <button
              key={item.name}
              onClick={() => {
                if (item.path) navigate(item.path);
                if (item.action) item.action();
                setIsOpen(false);
              }}
              className={`flex items-center gap-3 p-3 rounded-lg mx-2 transition-colors ${
                location.pathname === item.path 
                  ? 'bg-emerald-50 text-emerald-700' 
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              {item.icon}
              <span className="text-sm font-medium">{item.name}</span>
            </button>
          ))}
        </div>

        <button
          onClick={() => {
            localStorage.clear();
            navigate("/login");
          }}
          className="absolute bottom-4 left-4 right-4 flex items-center gap-3 p-3 mx-2 text-gray-600 hover:bg-gray-50 rounded-lg"
        >
          <FiLogOut className="w-5 h-5" />
          <span className="text-sm font-medium">Sair</span>
        </button>
      </motion.nav>

      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className="fixed md:hidden inset-0 bg-black/50 z-30"
        />
      )}

    {isModalOpen && companyId && (
              <TicketModal 
                closeModal={() => setIsModalOpen(false)}
                companyId={companyId}
                token={token}
              />
            )}
    </>
  );
};

export default Navbar;