// pages/Admin/Admin.js (Atualizado)
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import AdminSidebar from "./components/AdminSidebar";
import AdminNavbar from "./components/AdminNavbar";
import AdminUsuarios from "./components/AdminUsuarios";
import AdminChaves from "./components/AdminChaves";
import AdminEmpresas from "./components/AdminEmpresas";
import AdminVazamentos from "./components/AdminVazamentos";
import AdminTakedowns from "./components/AdminTakedowns";
const Admin = () => {
  const [activeTab, setActiveTab] = useState('usuarios');
  const [globalLoading, setGlobalLoading] = useState(false);

  const renderContent = () => {
    
    switch(activeTab) {
      case 'usuarios': return <AdminUsuarios setGlobalLoading={setGlobalLoading} />;
      case 'chaves': return <AdminChaves setGlobalLoading={setGlobalLoading} />;
      case 'empresas': return <AdminEmpresas setGlobalLoading={setGlobalLoading} />;
      case 'vazamentos': return <AdminVazamentos setGlobalLoading={setGlobalLoading} />;
      case 'takedowns': return <AdminTakedowns setGlobalLoading={setGlobalLoading} />;
      default: return <AdminUsuarios setGlobalLoading={setGlobalLoading} />;
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      <AdminSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
      
      <div className="flex-1 flex flex-col">
        <AdminNavbar />
        
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex-1 overflow-y-auto p-4 md:p-6"
        >
          <div className="max-w-7xl mx-auto">
            {renderContent()}
          </div>
        </motion.main>
      </div>
    </div>
  );
};

export default Admin;