import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FiKey, FiEdit2, FiTrash2, FiPlus, FiCopy, FiAlertCircle, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const ITEMS_PER_PAGE = 6;

const AdminChaves = () => {
  const [keys, setKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editModal, setEditModal] = useState(null);
  const [newModal, setNewModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', key: '', status: 'active' });
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchKeys();
  }, [token]);

  const fetchKeys = async () => {
    try {
      const response = await fetch('https://api.vazo.info/api/keys/list', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      if (!response.ok) throw new Error('Erro ao carregar chaves');
      const data = await response.json();
      setKeys(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = editModal 
        ? `https://api.vazo.info/api/keys/update/${editModal}`
        : 'https://api.vazo.info/api/keys/create';
      
      const method = editModal ? 'POST' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) throw new Error('Erro ao salvar chave');
      
      fetchKeys();
      setEditModal(null);
      setNewModal(false);
      setFormData({ name: '', key: '', status: 'active' });

    } catch (error) {
      setError(error.message);
    }
  };

  const handleDelete = async (keyId) => {
    try {
      await fetch(`https://api.vazo.info/api/keys/delete/${keyId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` }
      });
      fetchKeys();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleStatusChange = async (keyId, status) => {
    try {
      await fetch(`https://api.vazo.info/api/keys/${status ? 'active' : 'disable'}/${keyId}`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` }
      });
      fetchKeys();
    } catch (error) {
      setError(error.message);
    }
  };

  // Paginação
  const totalPages = Math.ceil(keys.length / ITEMS_PER_PAGE);
  const paginatedKeys = keys.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      {/* Modal de Edição/Criação */}
      {(editModal || newModal) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">
              {editModal ? 'Editar Chave' : 'Nova Chave'}
            </h3>
            
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Nome</label>
                  <input
                    type="text"
                    required
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.name}
                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">Chave</label>
                  <input
                    type="text"
                    required
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.key}
                    onChange={(e) => setFormData({...formData, key: e.target.value})}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">Status</label>
                  <select
                    className="w-full px-3 py-2 border rounded-lg"
                    value={formData.status}
                    onChange={(e) => setFormData({...formData, status: e.target.value})}
                  >
                    <option value="active">Ativa</option>
                    <option value="disabled">Desativada</option>
                  </select>
                </div>
              </div>

              <div className="mt-6 flex gap-3 justify-end">
                <button
                  type="button"
                  onClick={() => {
                    setEditModal(null);
                    setNewModal(false);
                    setFormData({ name: '', key: '', status: 'active' });
                  }}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700"
                >
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Cabeçalho */}
      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <h3 className="text-lg font-semibold">Gerenciamento de Chaves API</h3>
        <button 
          onClick={() => {
            setNewModal(true);
            setFormData({ name: '', key: '', status: 'active' });
          }}
          className="bg-emerald-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-emerald-700 transition-colors"
        >
          <FiPlus className="w-4 h-4" />
          Nova Chave
        </button>
      </div>

      {/* Loading e Erros */}
      {loading && (
        <div className="flex justify-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-600"></div>
        </div>
      )}

      {error && (
        <div className="bg-red-50 text-red-700 p-4 m-4 rounded-lg flex items-center gap-2">
          <FiAlertCircle className="w-5 h-5" />
          {error}
        </div>
      )}

      {/* Tabela */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50 text-gray-500 text-xs uppercase">
            <tr>
              <th className="px-6 py-3 text-left">Nome</th>
              <th className="px-6 py-3 text-left">Chave</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-right">Ações</th>
            </tr>
          </thead>
          
          <tbody className="divide-y divide-gray-200">
            {paginatedKeys.map((key) => (
              <motion.tr
                key={key.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="hover:bg-gray-50"
              >
                <td className="px-6 py-4 font-medium">{key.name}</td>
                <td className="px-6 py-4 flex items-center gap-2">
                  <span className="text-gray-600">••••••••{key.key.slice(-4)}</span>
                  <button 
                    onClick={() => navigator.clipboard.writeText(key.key)}
                    className="text-gray-400 hover:text-emerald-600"
                  >
                    <FiCopy className="w-4 h-4" />
                  </button>
                </td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => handleStatusChange(key.id, key.status !== 'active')}
                    className={`px-2 py-1 rounded-full text-xs cursor-pointer ${
                      key.status === 'active' 
                        ? 'bg-green-100 text-green-800 hover:bg-green-200' 
                        : 'bg-red-100 text-red-800 hover:bg-red-200'
                    }`}
                  >
                    {key.status}
                  </button>
                </td>
                <td className="px-6 py-4 text-right">
                  <div className="flex gap-2 justify-end">
                    <button 
                      onClick={() => {
                        setEditModal(key.id);
                        setFormData({
                          name: key.name,
                          key: key.key,
                          status: key.status
                        });
                      }}
                      className="text-gray-400 hover:text-emerald-600 p-1.5 rounded-lg"
                    >
                      <FiEdit2 className="w-5 h-5" />
                    </button>
                    <button 
                      onClick={() => handleDelete(key.id)}
                      className="text-gray-400 hover:text-red-600 p-1.5 rounded-lg"
                    >
                      <FiTrash2 className="w-5 h-5" />
                    </button>
                  </div>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Paginação */}
      <div className="p-4 border-t border-gray-200 flex justify-between items-center">
        <div className="text-sm text-gray-600">
          Mostrando {paginatedKeys.length} de {keys.length} chaves
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            className="p-2 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
          >
            <FiChevronLeft className="w-5 h-5" />
          </button>
          
          <span className="px-4 py-2 text-sm">
            Página {currentPage} de {totalPages}
          </span>

          <button
            onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
            disabled={currentPage === totalPages}
            className="p-2 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
          >
            <FiChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminChaves;