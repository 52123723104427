// pages/Admin/components/AdminSidebar.js (Aprimorado)
import React from 'react';
import { motion } from 'framer-motion';
import { 
  FiUsers, FiKey, FiBriefcase, FiAlertTriangle, FiTrash2, FiLogOut, FiSettings 
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const AdminSidebar = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const menuItems = [
    { id: 'usuarios', icon: <FiUsers />, label: 'Usuários' },
    { id: 'chaves', icon: <FiKey />, label: 'Chaves API' },
    { id: 'empresas', icon: <FiBriefcase />, label: 'Empresas' },
    { id: 'vazamentos', icon: <FiAlertTriangle />, label: 'Vazamentos' },
    { id: 'takedowns', icon: <FiTrash2 />, label: 'Takedowns' },
  ];

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <motion.aside
      initial={{ width: 80 }}
      animate={{ width: 256 }}
      className="h-screen bg-white border-r border-gray-200 hidden md:flex flex-col shadow-lg"
    >
      <div className="p-4 border-b border-gray-200 flex items-center justify-center">
        <img 
          src="https://vazo.info/vazo-png-logo.png" 
          alt="Admin Vazô" 
          className="h-10 transition-all duration-300"
        />
      </div>

      <nav className="flex-1 p-2 space-y-1 mt-4">
        {menuItems.map((item) => (
          <button
            key={item.id}
            onClick={() => setActiveTab(item.id)}
            className={`w-full flex items-center gap-3 p-3 rounded-lg transition-colors ${
              activeTab === item.id 
                ? 'bg-emerald-50 text-emerald-700 font-semibold' 
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <span className="text-xl">{item.icon}</span>
            <span className="text-sm">{item.label}</span>
          </button>
        ))}
      </nav>

      <button
        onClick={handleLogout}
        className="mt-auto flex items-center gap-3 p-3 mx-2 mb-4 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
      >
        <FiLogOut className="w-5 h-5" />
        <span className="text-sm">Sair</span>
      </button>
    </motion.aside>
  );
};

export default AdminSidebar;