import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiEdit2, FiTrash2, FiPlus, FiAlertCircle, FiX, FiChevronLeft, FiChevronRight, FiPlusCircle, FiCheckCircle } from 'react-icons/fi';

const ITEMS_PER_PAGE = 10;

const notificationVariants = {
  initial: { opacity: 0, y: 50, x: 50 },
  animate: { opacity: 1, y: 0, x: 0 },
  exit: { opacity: 0, x: 50, transition: { duration: 0.2 } }
};

const modalVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 }
};

const AdminUsuarios = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem('token');

  const initialUserData = {
    name: '',
    email: '',
    password: '',
    companyName: '',
    domains: [],
    days: 30,
    planType: 'basic',
    planPrice: '',
    phone: '',
    status: 'active'
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
      setSuccessMessage('');
    }, 5000);
    return () => clearTimeout(timer);
  }, [error, successMessage]);

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const fetchUsers = async () => {
    try {
      const response = await fetch('https://api.vazo.info/api/admin/users/list', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      if (!response.ok) throw new Error('Erro ao carregar usuários');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyDetails = async (companyId) => {
    if (!companyId) return [];
    try {
      const response = await fetch(`https://api.vazo.info/api/companies/info/${companyId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      if (!response.ok) throw new Error('Empresa não encontrada');
      const data = await response.json();
      return data.domains || [];
    } catch (error) {
      setError(error.message);
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const userResponse = await fetch(
        modalData?.id 
          ? `https://api.vazo.info/api/admin/users/update/${modalData.id}`
          : 'https://api.vazo.info/api/admin/users/create', 
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(modalData)
        }
      );

      if (!userResponse.ok) throw new Error('Erro ao salvar usuário');

      if (modalData.companyId) {
        const cleanedDomains = modalData.domains
          .map(d => d.trim())
          .filter(d => d !== '');

        const originalDomains = await fetchCompanyDetails(modalData.companyId);
        
        const hasDomainChanges = 
          JSON.stringify(cleanedDomains) !== JSON.stringify(originalDomains) &&
          cleanedDomains.length > 0;

        if (hasDomainChanges) {
          const companyResponse = await fetch(
            `https://api.vazo.info/api/companies/update-info/${modalData.companyId}`, 
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                companyId: modalData.companyId,
                domains: cleanedDomains,
                name: modalData.companyName || undefined
              })
            }
          );
          
          if (!companyResponse.ok) {
            const errorData = await companyResponse.json();
            throw new Error(errorData.error || 'Erro na empresa: ' + (errorData.message || ''));
          }
        }
      }

      setSuccessMessage(modalData?.id ? 'Usuário atualizado com sucesso!' : 'Usuário criado com sucesso!');
      fetchUsers();
      setModalData(null);
      setShowModal(false);

    } catch (error) {
      setError(error.message.replace('Erro na empresa: ', ''));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditUser = async (user) => {
    try {
      let originalDomains = [];
      if (user.companyId) {
        originalDomains = await fetchCompanyDetails(user.companyId);
      }
      
      setModalData({
        ...user,
        domains: originalDomains,
        originalDomains
      });
      setShowModal(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Tem certeza que deseja excluir este usuário e sua empresa associada?')) return;
    
    try {
      const userResponse = await fetch(`https://api.vazo.info/api/admin/users/${userId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!userResponse.ok) throw new Error('Erro ao deletar usuário');

      const user = users.find(u => u.id === userId);
      if (user?.companyId) {
        await fetch(`https://api.vazo.info/api/companies/delete/${user.companyId}`, {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${token}` }
        });
      }

      setUsers(users.filter(u => u.id !== userId));
      setSuccessMessage('Usuário e empresa excluídos com sucesso!');

    } catch (error) {
      setError(error.message);
    }
  };

  const totalPages = Math.ceil(users.length / ITEMS_PER_PAGE);
  const paginatedUsers = users.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <AnimatePresence>
        {(error || successMessage) && (
          <motion.div
            variants={notificationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={`fixed top-4 right-4 p-4 rounded-lg flex items-center gap-2 z-50 ${
              error ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'
            }`}
          >
            {error ? (
              <FiAlertCircle className="w-5 h-5" />
            ) : (
              <FiCheckCircle className="w-5 h-5" />
            )}
            <span>{error || successMessage}</span>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <h3 className="text-lg font-semibold">Gerenciamento de Usuários</h3>
        <button 
          onClick={() => {
            setModalData(initialUserData);
            setShowModal(true);
          }}
          className="bg-emerald-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-emerald-700 transition-colors"
        >
          <FiPlus className="w-4 h-4" />
          Novo Usuário
        </button>
      </div>

      {loading && (
        <div className="flex justify-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-600"></div>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50 text-gray-500 text-xs uppercase">
            <tr>
              <th className="px-6 py-3 text-left">Nome</th>
              <th className="px-6 py-3 text-left">Email</th>
              <th className="px-6 py-3 text-left">Empresa</th>
              <th className="px-6 py-3 text-left">Dias</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-right">Ações</th>
            </tr>
          </thead>
          
          <tbody className="divide-y divide-gray-200">
            {paginatedUsers.map((user) => (
              <motion.tr
                key={user.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="hover:bg-gray-50"
              >
                <td className="px-6 py-4 font-medium">{user.name}</td>
                <td className="px-6 py-4">{user.email}</td>
                <td className="px-6 py-4">
                  <div className="flex flex-col">
                    <span className="text-sm">{user.companyName || user.companyId || 'N/A'}</span>
                    {user.companyId && (
                      <span className="text-xs text-gray-500">ID: {user.companyId}</span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4">{user.days}</td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    user.status === 'active' 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {user.status}
                  </span>
                </td>
                <td className="px-6 py-4 text-right">
                  <div className="flex gap-2 justify-end">
                    <button 
                      onClick={() => handleEditUser(user)}
                      className="text-gray-400 hover:text-emerald-600 p-1.5 rounded-lg"
                    >
                      <FiEdit2 className="w-5 h-5" />
                    </button>
                    <button 
                      onClick={() => handleDeleteUser(user.id)}
                      className="text-gray-400 hover:text-red-600 p-1.5 rounded-lg"
                    >
                      <FiTrash2 className="w-5 h-5" />
                    </button>
                  </div>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>

      <AnimatePresence>
  {showModal && (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-6"
    >
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="bg-white rounded-xl w-full max-w-3xl p-8 relative max-h-[90vh] overflow-y-auto shadow-lg"
      >
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FiX className="w-5 h-5" />
        </button>

        <h3 className="text-2xl font-semibold mb-6 text-center text-gray-800">
          {modalData?.id ? 'Editar Usuário' : 'Novo Usuário'}
        </h3>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Nome e Nome Empresa */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Nome*</label>
              <input
                type="text"
                value={modalData?.name || ''}
                onChange={(e) => setModalData({ ...modalData, name: e.target.value })}
                className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Nome Empresa</label>
              <input
                type="text"
                value={modalData?.companyName || ''}
                onChange={(e) => setModalData({ ...modalData, companyName: e.target.value })}
                className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
              />
            </div>
          </div>

          {/* Email, Senha e Telefone */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Email*</label>
              <input
                type="email"
                value={modalData?.email || ''}
                onChange={(e) => setModalData({ ...modalData, email: e.target.value })}
                className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              />
            </div>

            {!modalData?.id && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Senha*</label>
                <input
                  type="password"
                  value={modalData?.password || ''}
                  onChange={(e) => setModalData({ ...modalData, password: e.target.value })}
                  className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
                  required
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Telefone*</label>
              <input
                type="text"
                value={modalData?.phone || ''}
                onChange={(e) => setModalData({ ...modalData, phone: e.target.value })}
                className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              />
            </div>
          </div>

          {/* Domínios */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Domínios</label>
            <div className="space-y-4">
              <AnimatePresence>
                {modalData?.domains?.map((domain, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="flex gap-4 items-center"
                  >
                    <input
                      type="text"
                      value={domain}
                      onChange={(e) => {
                        const newDomains = [...modalData.domains];
                        newDomains[index] = e.target.value;
                        setModalData({ ...modalData, domains: newDomains });
                      }}
                      className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
                      placeholder="Domínio"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        const newDomains = modalData.domains.filter((_, i) => i !== index);
                        setModalData({ ...modalData, domains: newDomains });
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FiX className="w-5 h-5" />
                    </button>
                  </motion.div>
                ))}
              </AnimatePresence>
              <button
                type="button"
                onClick={() => {
                  setModalData({
                    ...modalData,
                    domains: [...modalData.domains, ''],
                  });
                }}
                className="text-emerald-600 hover:text-emerald-700 flex items-center gap-1 text-sm"
              >
                <FiPlusCircle className="w-4 h-4" />
                Adicionar Domínio
              </button>
            </div>
          </div>

          {/* Plano, Dias e Status */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Dias*</label>
              <input
                type="number"
                min="0"
                value={modalData?.days || 0}
                onChange={(e) => setModalData({ ...modalData, days: e.target.value })}
                className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Status*</label>
              <select
                value={modalData?.status || 'active'}
                onChange={(e) => setModalData({ ...modalData, status: e.target.value })}
                className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
              >
                <option value="active">Ativo</option>
                <option value="inactive">Inativo</option>
              </select>
            </div>

            {/* Seleção de Plano */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Plano*</label>
              <select
                value={modalData?.planType || 'basic'}
                onChange={(e) => setModalData({ ...modalData, planType: e.target.value })}
                className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              >
                <option value="premium">Premium</option>
                <option value="basic">Basic</option>
              </select>
            </div>

            {/* Valor do Plano */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Valor do Plano*</label>
              <input
                type="number"
                value={modalData?.planPrice || ''}
                onChange={(e) => setModalData({ ...modalData, planPrice: e.target.value })}
                className="w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              />
            </div>
          </div>

          {/* Botões de Ação */}
          <div className="mt-8 flex justify-end gap-4">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              disabled={isSubmitting}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 flex items-center gap-2 transition-colors"
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity }}
                  className="w-4 h-4 border-2 border-t-transparent border-white rounded-full"
                />
              )}
              {modalData?.id ? 'Salvar' : 'Criar'}
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  )}
</AnimatePresence>


      <div className="p-4 border-t border-gray-200 flex justify-between items-center">
        <div className="text-sm text-gray-600">
          Mostrando {paginatedUsers.length} de {users.length} usuários
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            className="p-2 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
          >
            <FiChevronLeft className="w-5 h-5" />
          </button>
          
          <span className="px-4 py-2 text-sm">
            Página {currentPage} de {totalPages}
          </span>

          <button
            onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
            disabled={currentPage === totalPages}
            className="p-2 border rounded-lg hover:bg-gray-50 disabled:opacity-50"
          >
            <FiChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminUsuarios;