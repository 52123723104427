import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import LeakDetailModal from '../../System/components/LeakDetailModal';
import { FiEye } from 'react-icons/fi';

const AdminVazamentos = ({ setGlobalLoading }) => {
  // Adicione esta função dentro do componente
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('pt-BR');
  };
  const [companyId, setCompanyId] = useState('');
  const [companyInfo, setCompanyInfo] = useState(null);
  const [leaks, setLeaks] = useState([]);
  const [selectedLeak, setSelectedLeak] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const token = localStorage.getItem('token');

  const fetchCompanyData = async () => {
    setGlobalLoading(true);
    try {
      // Buscar informações da empresa
      const companyRes = await fetch(`https://api.vazo.info/api/companies/info/${companyId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (!companyRes.ok) throw new Error('Empresa não encontrada');
      const companyData = await companyRes.json();

      // Buscar status da empresa
      const statusRes = await fetch(`https://api.vazo.info/api/status/${companyId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (!statusRes.ok) throw new Error('Erro ao buscar vazamentos');
      const statusData = await statusRes.json();

      // Combinar vazamentos de hoje e históricos
      const combinedLeaks = [
        ...statusData.vazamentos_hoje.leaks.map(leak => ({
          ...leak,
          date: statusData.ultima_consulta // Usa data da última consulta
        })),
        ...statusData.vazamentos_total.leaks
      ];

      setCompanyInfo({
        ...companyData,
        domain: companyData.domains?.[0] || 'N/A'
      });
      setLeaks(combinedLeaks);
      setError('');

    } catch (err) {
      setError(err.message);
      setCompanyInfo(null);
      setLeaks([]);
    } finally {
      setGlobalLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!companyId.trim()) return;
    fetchCompanyData();
  };

  const paginatedLeaks = leaks.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <div className="p-4 border-b border-gray-200 space-y-4">
        <h3 className="text-lg font-semibold">Consulta de Vazamentos por Empresa</h3>
        
        <form onSubmit={handleSubmit} className="flex gap-2">
          <input
            type="text"
            value={companyId}
            onChange={(e) => setCompanyId(e.target.value)}
            placeholder="Insira o ID da empresa"
            className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-emerald-500"
          />
          <button
            type="submit"
            className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700"
          >
            Buscar
          </button>
        </form>

        {error && (
          <div className="bg-red-50 text-red-700 p-3 rounded-lg">
            {error}
          </div>
        )}

        {companyInfo && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-semibold">{companyInfo.name}</h4>
            <p className="text-sm text-gray-600">Domínio: {companyInfo.domain}</p>
          </div>
        )}
      </div>

      {leaks.length > 0 && (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50 text-gray-500 text-xs uppercase">
              <tr>
                <th className="px-6 py-3 text-left">Data</th>
                <th className="px-6 py-3 text-left">ID do Vazamento</th>
                <th className="px-6 py-3 text-left">Status</th>
                <th className="px-6 py-3 text-right">Ações</th>
              </tr>
            </thead>
            
            <tbody className="divide-y divide-gray-200">
              {paginatedLeaks.map((leak, index) => (
                <motion.tr
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="hover:bg-gray-50"
                >
                  <td className="px-6 py-4">{leak.date ? formatDate(leak.date) : 'N/A'}</td>

                  <td className="px-6 py-4 font-mono text-sm">
                    {leak.systemId?.slice(0, 8)}...
                  </td>
                  <td className="px-6 py-4">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      leak.status === 'Aberto' 
                        ? 'bg-red-100 text-red-800' 
                        : 'bg-green-100 text-green-800'
                    }`}>
                      {leak.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <button
                      onClick={() => {
                        setSelectedLeak(leak);
                        setIsModalOpen(true);
                      }}
                      className="text-gray-400 hover:text-emerald-600 p-1.5 rounded-lg"
                    >
                      <FiEye className="w-5 h-5" />
                    </button>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>

          {/* Paginação */}
          <div className="flex justify-between items-center px-6 py-4 border-t border-gray-200">
            <button
              className="px-4 py-2 text-gray-600 hover:text-emerald-600 disabled:opacity-50"
              onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
              disabled={currentPage === 1}
            >
              Anterior
            </button>
            <span className="text-sm text-gray-600">
              Página {currentPage} de {Math.ceil(leaks.length / itemsPerPage)}
            </span>
            <button
              className="px-4 py-2 text-gray-600 hover:text-emerald-600 disabled:opacity-50"
              onClick={() => setCurrentPage(p => Math.min(Math.ceil(leaks.length / itemsPerPage), p + 1))}
              disabled={currentPage === Math.ceil(leaks.length / itemsPerPage)}
            >
              Próxima
            </button>
          </div>
        </div>
      )}

      {isModalOpen && selectedLeak && (
        <LeakDetailModal
          leak={selectedLeak}
          closeModal={() => setIsModalOpen(false)}
          companyId={companyId}
          token={token}
          formatDate={formatDate}
        />
      )}
    </div>
  );
};

export default AdminVazamentos;