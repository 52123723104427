import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/Landing/LandingPage";
import LoginPage from "./pages/Auth/LoginPage";
import Dashboard from "./pages/System/Dashboard";
import Configuracoes from "./pages/System/Configuracoes";
import Financeiro from "./pages/System/Financeiro";
import Admin from "./pages/Admin/Admin";

import Privacidade from "./pages/Utils/Privacidade";
import Blog from "./pages/Utils/Blog";

import PrivateRoute from "./utils/PrivateRoute"; // Importando o PrivateRoute

import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/termos-e-condicoes" element={<Privacidade />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/uptime" element={<LoginPage />} />



        {/* Usando PrivateRoute para proteger o dashboard */}
        <Route
          path="/dashboard"
          element={<PrivateRoute element={Dashboard} />}
        />

        
        {/* Usando PrivateRoute para proteger o Config */}
        <Route
          path="/dashboard/configuracoes"
          element={<PrivateRoute element={Configuracoes} />}
        />

                
        {/* Usando PrivateRoute para proteger o Financeiro */}
        <Route
          path="/dashboard/financeiro"
          element={<PrivateRoute element={Financeiro} />}
        />

        <Route 
        path="/admin" 
        element={<PrivateRoute element={Admin} adminOnly={true} />} 
      />
    </Routes>
    </div>
  );
}


export default App;
