import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { LockClosedIcon, EnvelopeIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const response = await fetch('https://api.vazo.info/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Erro ao fazer login');
      }

      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));

      if (data.user.permissions && data.user.permissions.includes("admin")) {
        navigate("/admin");
      } else {
        navigate("/dashboard");
      }

      setTimeout(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
      }, 3600000);

    } catch (err) {
      setError(err.message || "Erro ao fazer login. Verifique suas credenciais.");
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col lg:flex-row-reverse">
      <div className="hidden lg:block relative w-1/2 h-screen">
        <div className="absolute inset-0 bg-gradient-to-r from-emerald-900/60 to-gray-900/40" />
        <img
          src="https://cdn.prod.website-files.com/61e1949f83dad6619067b896/664e4b6a2e206e0ecb507f07_Rectangle%20911.jpg"
          alt="Segurança digital"
          className="w-full h-full object-cover object-right"
        />
        <div className="absolute inset-0 flex flex-col justify-center items-end px-16 text-white">
          <h2 className="text-4xl font-bold mb-6 leading-tight max-w-md text-right">
            Gestão Inteligente de Segurança Digital
          </h2>
          <p className="text-xl opacity-90 max-w-md text-right">
            Monitoramento em tempo real e proteção avançada contra ameaças cibernéticas
          </p>
        </div>
      </div>

      <div className="w-full lg:w-1/2 flex items-center justify-center p-6 min-h-screen lg:min-h-0">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="w-full max-w-md bg-white rounded-2xl shadow-xl lg:mx-0 mx-auto"
        >
          <div className="p-8">
            <div className="text-center mb-10">
              <ShieldCheckIcon className="h-14 w-14 text-emerald-600 mx-auto mb-6" />
              <h1 className="text-3xl font-bold text-gray-900 mb-2">
                Acesso Seguro
              </h1>
              <p className="text-gray-600">Use suas credenciais corporativas</p>
            </div>

            <AnimatePresence>
              {error && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="bg-red-50 text-red-700 p-3 rounded-lg mb-6 text-sm flex items-center gap-2"
                >
                  <svg className="w-5 h-5 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
                  </svg>
                  <span>{error}</span>
                </motion.div>
              )}
            </AnimatePresence>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-5">
                <div className="relative">
                  <EnvelopeIcon className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="email@empresa.com"
                    className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 focus:outline-none"
                    disabled={isLoading}
                  />
                </div>

                <div className="relative">
                  <LockClosedIcon className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="••••••••"
                    className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 focus:outline-none"
                    disabled={isLoading}
                  />
                </div>
              </div>

              <motion.button
                type="submit"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={isLoading || !validateEmail(email) || password.length < 8}
                className="w-full py-3.5 bg-gradient-to-r from-emerald-600 to-green-500 text-white font-semibold rounded-lg transition-all disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center gap-2">
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Verificando...
                  </div>
                ) : (
                  'Acessar Sistema'
                )}
              </motion.button>
            </form>

            <div className="mt-8 text-center">
              <a 
                href="#recovery" 
                className="text-sm text-emerald-600 hover:text-emerald-700 font-medium"
              >
                Esqueceu a senha?
              </a>
            </div>
          </div>

          <div className="bg-gray-50 p-5 text-center border-t border-gray-100">
            <p className="text-sm text-gray-600">
              Primeiro acesso?{' '}
              <a 
                href="/#pricing" 
                className="font-medium text-emerald-600 hover:text-emerald-700"
              >
                Contrate um Plano
              </a>
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LoginPage;