// pages/Admin/components/AdminTakedowns.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FiTrash2, FiCheck, FiExternalLink, FiClock } from 'react-icons/fi';

const AdminTakedowns = ({ setGlobalLoading }) => {
  const [requests, setRequests] = useState([]);
  const token = localStorage.getItem('token');

  // Função de formatação de data
  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  useEffect(() => {
    const fetchTakedowns = async () => {
      setGlobalLoading(true);
      try {
        const response = await fetch('https://api.vazo.info/api/takedown/list', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        
        if (!response.ok) throw new Error('Erro ao carregar solicitações');
        const data = await response.json();
        setRequests(data);
      } catch (error) {
        console.error('Erro:', error);
      } finally {
        setGlobalLoading(false);
      }
    };

    fetchTakedowns();
  }, [token, setGlobalLoading]);

  const handleResolveTakedown = async (requestId, companyId, systemId) => {
    try {
      const confirmed = window.confirm('Confirmar resolução deste takedown?');
      if (!confirmed) return;

      const response = await fetch(
        `https://api.vazo.info/api/takedown/${companyId}/${systemId}`,
        {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (!response.ok) throw new Error('Erro ao resolver takedown');
      
      setRequests(requests.filter(req => req.systemId !== systemId));
    } catch (error) {
      console.error('Erro:', error);
      alert(error.message);
    }
  };

  const handleDeleteRequest = async (requestId) => {
    try {
      const confirmed = window.confirm('Excluir permanentemente esta solicitação?');
      if (!confirmed) return;

      // Adicionar chamada API para exclusão se necessário
      setRequests(requests.filter(req => req.id !== requestId));
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200">
      <div className="p-4 border-b border-gray-200">
        <h3 className="text-lg font-semibold">Solicitações de Takedown</h3>
        <p className="text-sm text-gray-500 mt-1">
          {requests.length} solicitações encontradas
        </p>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50 text-gray-500 text-xs uppercase">
            <tr>
              <th className="px-6 py-3 text-left">Empresa</th>
              <th className="px-6 py-3 text-left">Domínio</th>
              <th className="px-6 py-3 text-left">Data</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-right">Ações</th>
            </tr>
          </thead>
          
          <tbody className="divide-y divide-gray-200">
            {requests.map((request) => (
              <motion.tr
                key={request.systemId}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="hover:bg-gray-50"
              >
                <td className="px-6 py-4 font-medium">{request.companyName}</td>
                <td className="px-6 py-4">{request.domain}</td>
                <td className="px-6 py-4">
                  <div className="flex items-center gap-1 text-sm text-gray-600">
                    <FiClock className="w-4 h-4" />
                    {formatDate(request.requestedAt)}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    request.status === 'resolved' 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {request.status === 'resolved' ? 'Resolvido' : 'Pendente'}
                  </span>
                </td>
                <td className="px-6 py-4 text-right">
                  <div className="flex gap-2 justify-end">
                    <a
                      href={`https://intelx.io/?did=${request.systemId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-emerald-600 hover:text-emerald-700 p-1.5 rounded-lg"
                      title="Ver no Intelx"
                    >
                      <FiExternalLink className="w-5 h-5" />
                    </a>
                    
                    {request.status !== 'resolved' && (
                      <button
                        onClick={() => handleResolveTakedown(request.id, request.companyId, request.systemId)}
                        className="text-gray-400 hover:text-green-600 p-1.5 rounded-lg"
                        title="Marcar como resolvido"
                      >
                        <FiCheck className="w-5 h-5" />
                      </button>
                    )}
                    
                    <button
                      onClick={() => handleDeleteRequest(request.id)}
                      className="text-gray-400 hover:text-red-600 p-1.5 rounded-lg"
                      title="Excluir solicitação"
                    >
                      <FiTrash2 className="w-5 h-5" />
                    </button>
                  </div>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminTakedowns;