import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const Modal = ({ onClose, title, content, image, sourceButton }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/70 flex items-center justify-center z-50 backdrop-blur-sm"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="bg-white text-gray-900 rounded-xl p-4 md:p-6 max-w-3xl w-full mx-4 max-h-[90vh] overflow-y-auto shadow-2xl relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Botão de fechar (X) */}
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          aria-label="Fechar"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {image && (
          <div className="mb-4 rounded-lg overflow-hidden aspect-video">
            <img
              src={image}
              alt={title}
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
        )}
        <h2 className="text-2xl md:text-3xl font-bold mb-4">{title}</h2>
        <div
          className="prose prose-sm md:prose md:prose-base text-gray-700 leading-relaxed space-y-4"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {sourceButton && (
          <div className="mt-4">
            <div dangerouslySetInnerHTML={{ __html: sourceButton }} />
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

const Blog = () => {
  const [selectedPost, setSelectedPost] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Todas');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = selectedPost ? 'hidden' : 'auto';
    return () => (document.body.style.overflow = 'auto');
  }, [selectedPost]);

  const posts = [
    {
      id: 1,
      title: "Banco Neon: Vazam dados de 30 milhões de clientes",
      excerpt: "Ataque cibernético expôs dados sensíveis de milhões de usuários da fintech brasileira.",
      content: `
        <p>O Banco Neon, uma fintech brasileira, sofreu um ataque cibernético que comprometeu os dados de cerca de 30 milhões de clientes. Informações como CPF, telefone e dados bancários foram expostas, levantando preocupações sobre segurança digital.</p>
        <div class="bg-[#119f5310] p-4 rounded-lg shadow-sm">
          <h3 class="font-semibold text-[#119f53] mb-2">O que fazer agora?</h3>
          <p>Especialistas recomendam monitoramento de contas e troca imediata de senhas para mitigar riscos.</p>
        </div>
        <p>A empresa está investigando o incidente e promete reforçar sua infraestrutura de segurança.</p>
      `,
      image: "./Vazo-Neonvazamento.png",
      date: "06 de Março, 2025",
      category: "Notícias",
      sourceButton: `<a href="https://www.tecmundo.com.br/seguranca/402470-banco-neon-vazam-dados-de-30-milhoes-de-clientes.htm" target="_blank" class="text-blue-600 text-sm underline">Leia a matéria completa</a>`,
    },
    {
      id: 2,
      title: "5 dicas para proteger seus dados online",
      excerpt: "Práticas simples e eficazes para garantir sua segurança na internet.",
      content: `
        <p>Proteger seus dados online é essencial no mundo digital atual. Confira cinco dicas práticas para começar:</p>
        <ol class="list-decimal pl-6 space-y-3">
          <li><strong>Senhas fortes:</strong> Use combinações únicas e complexas para cada serviço.</li>
          <li><strong>Autenticação 2FA:</strong> Adicione uma camada extra de segurança.</li>
          <li><strong>Atualizações:</strong> Mantenha sistemas e aplicativos sempre atualizados.</li>
          <li><strong>VPN:</strong> Utilize redes privadas em conexões públicas.</li>
          <li><strong>Monitoramento:</strong> Fique atento a atividades suspeitas nas suas contas.</li>
        </ol>
      `,
      image: "./Vazo-5dicas.png",
      date: "05 de Março, 2025",
      category: "Dicas",
    },
    {
      id: 3,
      title: "Como a Vazô protege seus dados",
      excerpt: "Tecnologias avançadas que garantem a segurança dos seus dados pessoais.",
      content: `
        <p>A Vazô utiliza uma abordagem avançada para proteger seus dados pessoais:</p>
        <ul class="list-disc pl-6 space-y-3">
          <li><strong>Criptografia AES-256:</strong> Segurança de nível bancário para seus dados.</li>
          <li><strong>Monitoramento 24/7:</strong> Detecção de ameaças em tempo real.</li>
          <li><strong>Busca na dark web:</strong> Identificação proativa de vazamentos.</li>
        </ul>
        <p>Com essas ferramentas, a Vazô mantém seus dados fora do alcance de hackers e vazamentos.</p>
      `,
      image: "./Vazo-Comoavazo.png",
      date: "04 de Março, 2025",
      category: "Soluções",
    },
  ];

  const categories = ['Todas', 'Notícias', 'Dicas', 'Soluções'];

  const filteredPosts = posts.filter((post) => {
    const matchesSearch = post.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'Todas' || post.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="font-sans bg-gray-50 min-h-screen overflow-x-hidden">
      {/* Header */}
      <motion.header
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        className="fixed w-full bg-white/95 backdrop-blur-md z-50 shadow-sm border-b border-gray-200/80"
      >
        <div className="container mx-auto px-4 lg:px-8 py-4 flex justify-between items-center">
          <Link to="/" className="hover:opacity-90 transition-opacity">
            <motion.div whileHover={{ scale: 1.05 }} className="flex items-center gap-2">
              <img src="./vazo-png-logo.png" alt="Vazô Logo" className="h-8 lg:h-9 w-auto" />
            </motion.div>
          </Link>
          <Link to="/login">
            <motion.button
              whileHover={{ scale: 1.05 }}
              className="bg-gradient-to-r from-[#119f53] to-[#0d7a3f] text-white px-6 py-2.5 rounded-lg font-medium shadow-sm hover:shadow-md transition-shadow"
            >
              Acessar Plataforma
            </motion.button>
          </Link>
        </div>
      </motion.header>

      {/* Main Content */}
      <main className="pt-32 pb-16">
        <div className="container mx-auto px-4 lg:px-8">
          {/* Search Bar and Filter */}
          <div className="mb-6 flex flex-col sm:flex-row gap-3 items-center justify-between">
            <div className="relative w-full sm:w-2/3">
              <input
                type="text"
                placeholder="Pesquisar notícias..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-3 pl-10 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#119f53] shadow-sm bg-white text-gray-800 placeholder-gray-400 transition-all"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <div className="relative">
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-white border border-gray-200 shadow-sm hover:bg-gray-100 transition-all"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#119f53]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                </svg>
                <span className="text-gray-700">Filtro</span>
              </button>
              {isFilterOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute right-0 mt-2 w-40 bg-white rounded-lg shadow-lg border border-gray-200 z-10"
                >
                  {categories.map((category) => (
                    <button
                      key={category}
                      onClick={() => {
                        setSelectedCategory(category);
                        setIsFilterOpen(false);
                      }}
                      className={`w-full text-left px-4 py-2 text-sm ${
                        selectedCategory === category
                          ? 'bg-[#119f53] text-white'
                          : 'text-gray-700 hover:bg-gray-100'
                      }`}
                    >
                      {category}
                    </button>
                  ))}
                </motion.div>
              )}
            </div>
          </div>

          {/* News List */}
          <div className="space-y-4">
            {filteredPosts.map((post) => (
              <motion.article
                key={post.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ y: -2 }}
                className="bg-white rounded-lg p-4 flex flex-col sm:flex-row items-start gap-4 shadow-md hover:shadow-lg transition-all cursor-pointer border border-gray-100"
                onClick={() => setSelectedPost(post)}
              >
                {post.image && (
                  <div className="relative w-full sm:w-40 rounded-md overflow-hidden">
                    <div className="aspect-video sm:aspect-auto sm:h-24">
                      <img
                        src={post.image}
                        alt={post.title}
                        className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                      />
                    </div>
                  </div>
                )}
                <div className="flex-1">
                  <div className="flex items-center gap-2 mb-1">
                    <span className="text-xs text-[#119f53] font-medium bg-[#119f5310] px-2 py-1 rounded-full">
                      {post.category}
                    </span>
                    <span className="text-xs text-gray-500">{post.date}</span>
                  </div>
                  <h3 className="text-lg md:text-xl font-semibold text-gray-900 mb-1">
                    {post.title}
                  </h3>
                  <p className="text-gray-600 text-sm line-clamp-2">{post.excerpt}</p>
                </div>
                <motion.div whileHover={{ x: 5 }} className="hidden sm:block text-[#119f53]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </motion.div>
              </motion.article>
            ))}
            {filteredPosts.length === 0 && (
              <p className="text-center text-gray-600 text-lg py-6">
                Nenhum post encontrado. Tente outra busca ou filtro!
              </p>
            )}
          </div>
        </div>
      </main>

      {/* Modal */}
      <AnimatePresence>
        {selectedPost && <Modal {...selectedPost} onClose={() => setSelectedPost(null)} />}
      </AnimatePresence>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-12 mt-16">
        <div className="container mx-auto px-4 lg:px-8">
          <div className="grid md:grid-cols-4 gap-8">
            <div className="space-y-4">
              <img src="./vazo-png-logo-invertido.png" alt="Logo" className="h-8 mb-4" />
              <p className="text-sm leading-relaxed">
                Proteção inteligente contra vazamentos de dados pessoais
              </p>
            </div>
            <div className="space-y-4">
              <h3 className="text-white font-semibold">Contato</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    contato@vazo.info
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    +55 16 99437-9993
                  </a>
                </li>
                <li>R. Fictícia, 123 - São Paulo/SP</li>
              </ul>
            </div>
            <div className="space-y-4">
              <h3 className="text-white font-semibold">Legal</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Política de Privacidade
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Termos de Uso
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    LGPD
                  </a>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <h3 className="text-white font-semibold">Recursos</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Documentação
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Status
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-800 mt-12 pt-8 text-center text-sm">
            © {new Date().getFullYear()} Vazô Tecnologia Ltda. - CNPJ 59.643.373/0001
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Blog;
